exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-elite-careers-js": () => import("./../../../src/pages/elite-careers.js" /* webpackChunkName: "component---src-pages-elite-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-diagnostics-js": () => import("./../../../src/pages/services/diagnostics.js" /* webpackChunkName: "component---src-pages-services-diagnostics-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-standard-care-js": () => import("./../../../src/pages/services/standard-care.js" /* webpackChunkName: "component---src-pages-services-standard-care-js" */),
  "component---src-pages-services-therapeutics-js": () => import("./../../../src/pages/services/therapeutics.js" /* webpackChunkName: "component---src-pages-services-therapeutics-js" */),
  "component---src-pages-services-urgent-care-js": () => import("./../../../src/pages/services/urgent-care.js" /* webpackChunkName: "component---src-pages-services-urgent-care-js" */)
}

